<template lang="pug">
header(:class='{ extended: isMenuBurgerOpen }')
  BurgerButton(:isMenuBurgerOpen='isMenuBurgerOpen', @toggle-burger-menu='isMenuBurgerOpen = !isMenuBurgerOpen')
  .header-brand(@click='$router.push({ name: "Home" })')
    img(src='@/assets/logo.png', alt='Logo de Blast')
  nav
    router-link(v-for='elt in navElements', :to='{ name: elt.name }', :key='"route:" + elt.name') {{ elt.meta.label }}
    .cardo.card-on-nav(@click='toggleCardPopup')
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75 8.25C3.55109 8.25 3.36032 8.32902 3.21967 8.46967C3.07902 8.61032 3 8.80109 3 9V19.125C3 20.5425 4.2075 21.75 5.625 21.75H18.375C19.7925 21.75 21 20.6011 21 19.1836V9C21 8.80109 20.921 8.61032 20.7803 8.46967C20.6397 8.32902 20.4489 8.25 20.25 8.25H3.75Z" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.5 8.25V6.75C7.5 5.55653 7.97411 4.41193 8.81802 3.56802C9.66193 2.72411 10.8065 2.25 12 2.25V2.25C13.1935 2.25 14.3381 2.72411 15.182 3.56802C16.0259 4.41193 16.5 5.55653 16.5 6.75V8.25" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

  .cardo.card-out-of-nav(@click='toggleCardPopup')
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 8.25C3.55109 8.25 3.36032 8.32902 3.21967 8.46967C3.07902 8.61032 3 8.80109 3 9V19.125C3 20.5425 4.2075 21.75 5.625 21.75H18.375C19.7925 21.75 21 20.6011 21 19.1836V9C21 8.80109 20.921 8.61032 20.7803 8.46967C20.6397 8.32902 20.4489 8.25 20.25 8.25H3.75Z" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 8.25V6.75C7.5 5.55653 7.97411 4.41193 8.81802 3.56802C9.66193 2.72411 10.8065 2.25 12 2.25V2.25C13.1935 2.25 14.3381 2.72411 15.182 3.56802C16.0259 4.41193 16.5 5.55653 16.5 6.75V8.25" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  .card-popup(:class='{ shown: isCardPopupOpen }')
    .card-popup__header
      span.card-popup__header__title Panier
      img.card-popup__header__close-button(
        src='@/assets/bouton-x.svg',
        alt='Bouton de fermeture',
        width='15',
        @click='isCardPopupOpen = false'
      )
    template(v-if='itemsOnCard.length')
      .card-popup__item(v-for='item in itemsOnCard')
        .card-popup__item__infos
          span.card-popup__item__infos__title Programme d'auto-coaching : {{ item.data.title[0].text }}
          prismic-rich-text(:field='item.data.card_infos')
        span.card-popup__item__price {{ item.data.price[0].text }}
        button(@click='removeItemFromCard(item.id)')
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.66669H2.77778H17" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.44433 4.6667V2.88892C5.44433 2.41743 5.63164 1.96524 5.96503 1.63184C6.29843 1.29845 6.75062 1.11115 7.22211 1.11115H10.7777C11.2492 1.11115 11.7013 1.29845 12.0347 1.63184C12.3681 1.96524 12.5554 2.41743 12.5554 2.88892V4.6667M15.2221 4.6667V17.1111C15.2221 17.5826 15.0348 18.0348 14.7014 18.3682C14.368 18.7016 13.9158 18.8889 13.4443 18.8889H4.55545C4.08395 18.8889 3.63177 18.7016 3.29837 18.3682C2.96497 18.0348 2.77767 17.5826 2.77767 17.1111V4.6667H15.2221Z" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.22233 9.11115V14.4445" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.7777 9.11115V14.4445" stroke="#001A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      a.card-popup__validation-button(:href='cardValidationLink') Valider votre panier

    p(v-else) Votre panier est vide
</template>

<script>
import getNavElements from '@/utils/NavElements'

export default {
  name: 'Header',
  components: {
    BurgerButton: () => import('./BurgerButton')
  },
  data() {
    return {
      isMenuBurgerOpen: false,
      isCardPopupOpen: false
    }
  },
  computed: {
    navElements() {
      return getNavElements(this.$router.options.routes)
    },
    itemsOnCard() {
      return this.$store.state.card
    },
    cardValidationLink() {
      const baseUrl = 'https://pcharneau.systeme.io/'
      const coursesString = this.itemsOnCard.reduce((acc, currentItem) => {
        acc += `${currentItem.uid}#`
        return acc
      }, '')
      return `${baseUrl}${coursesString.substring(0, coursesString.length - 1)}`
    }
  },
  watch: {
    itemsOnCard: function () {
      this.isCardPopupOpen = true
    },
    $route: function () {
      this.isMenuBurgerOpen = false
    }
  },
  methods: {
    toggleCardPopup() {
      this.isCardPopupOpen = !this.isCardPopupOpen
    },
    removeItemFromCard(itemId) {
      this.$store.commit('REMOVE_ITEM_FROM_CARD', itemId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/colors';
@import '@/styles/mixins';

header {
  height: $headerHeight;
  background-color: $white;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
  transition: height 0.4s ease-in-out;
  display: flex;
  justify-content: space-between;
  padding: 0 $mainHorizontalPadding;
  overflow: hidden;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);

  @include medium-screen {
    padding: 0 $mainHorizontalPaddingMedium;
  }
  @include large-screen {
    padding: 0 $mainHorizontalPaddingLarge;
  }

  > * {
    height: $headerHeight;
    display: flex;
    align-items: center;
  }

  &.extended {
    height: 100vh;
  }

  @include medium-screen {
    font-weight: 500;
  }

  .header-brand {
    cursor: pointer;
    img {
      height: 70%;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    background-color: rgba($green, 0.4);
    position: absolute;
    width: 100%;
    margin-left: -$mainHorizontalPadding;
    height: calc(100vh - #{$headerHeight});
    top: $headerHeight;
    padding: $mainHorizontalPadding;
    align-items: stretch;

    .card-on-nav {
      display: none;
      cursor: pointer;
    }

    .card-out-of-nav {
      cursor: pointer;
    }

    > * {
      margin: 0.5rem 0;
      color: $darkBlue;
      text-decoration: none;
      padding: 0.5rem 1rem;
      border-radius: 50px;
      font-weight: 600;

      &.router-link-exact-active {
        color: white;
        background-color: $darkBlue;
      }
    }

    @include medium-screen {
      position: relative;
      flex-direction: row;
      top: inherit;
      height: $headerHeight;
      width: fit-content;
      background-color: transparent;
      padding: 0;
      align-items: center;

      > * {
        margin: 0rem 1rem;
        padding: 0;

        &:hover {
          font-weight: 700;
        }

        &.router-link-exact-active {
          color: $orange;
          background-color: inherit;
          font-weight: 700;
        }
      }

      .card-on-nav {
        display: block;
      }
    }
  }

  .card-out-of-nav {
    @include medium-screen {
      display: none;
    }
  }

  .card-popup {
    width: 400px;
    min-height: 100px;
    background-color: $white;
    position: fixed;
    right: $mainHorizontalPadding;
    margin-top: calc(#{$headerHeight} + 1rem);
    box-shadow: 0px 5px 12px 0px rgba(66, 66, 66, 0.6);
    border-radius: 20px;
    display: none;
    padding: 1rem;
    height: fit-content;
    max-width: calc(100% - #{$mainHorizontalPadding} * 4);

    &.shown {
      display: block;
    }

    &__validation-button {
      text-decoration: none;
      color: $darkBlue;
      font-weight: bold;
      margin-top: 1rem;
      width: fit-content;
      margin-left: auto;
      display: block;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &__title {
        font-weight: bold;
        font-family: 'Hind Vadodara', sans-serif;
        font-size: 2rem;
        display: block;
        margin-bottom: 1rem;
      }
      &__close-button {
        cursor: pointer;
      }
    }

    @include medium-screen {
      right: $mainHorizontalPaddingMedium;
    }
    @include large-screen {
      right: $mainHorizontalPaddingLarge;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $darkBlue;
      margin: 0rem -1rem;
      padding: 1rem;
      color: $white;

      &__infos {
        &__title {
          line-height: 1.2rem;
        }
        div {
          margin-top: 0.5rem;

          &::v-deep {
            ul {
              li {
                margin-bottom: 0.5rem;
              }
            }
          }
        }
      }

      &__price {
        white-space: nowrap;
        margin: 0 0.5rem;
        margin-left: auto;
      }

      button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: none;
        background-color: $white;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
